<template>
<div class="relative">
    <!-- <div class="state_graph_tooltip" v-if="selectedstate.name"> -->
        <div class="state_graph_tooltip client_state_graph" v-if="selectedstate.name"  :style="{top:selectedstate.y + 'px',left:selectedstate.x+'px'}" >
        <h6>{{checkProperty(selectedstate, "name")}}</h6>
        <ul>
            <li :class="'status'+item.statusId" v-for="item in selectedstate.data" :key="item.statusId">
                <label>
                    <span v-if="item.statusId==1">Filed</span>


                   </label>

                <p>{{item.totalAmount | currency }}</p>
                <label class="sm" >
                <span v-if="item.statusId==2">Due On {{item.dueDate | formatDate}}</span>

                <span  v-if="item.statusId==3">Overdue On {{item.dueDate | formatDate}}</span>
                   </label>

            </li>
             <!-- <li >
                <label>status -<b>05</b></label>

                <p><small>$200</small></p>
            </li> -->
        </ul>
    </div>
    <div id="clientstatefilingsChart" class="statediv"> </div>

</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);
import _ from "lodash";

export default {
    name: 'clientstatefilings',
    props: {
        usstateList:{
            type: [Object, Array],
            default: null,

        },
        chartData: {
            type: [Object, Array],
            default: null,

        },
    },
    watch: {
        chartData(val) {
            let self = this;

            //this.usstateList

            var ids = [];
            this.formateddata = [];
            val.forEach(element => {

                var stateObject = _.find(this.usstateList, {
                    "_id": element._id
                });
                if(stateObject){
                     var abbr = this.states_hash[stateObject.name]
                ids.push("US-" + abbr)

                var _notes = [{
                    "status": "Filed",
                    statusId: 1,
                    "totalClients": 0,
                    "totalAmount":0
                }, {
                    "status": "Due",
                    statusId: 2,
                    "totalClients": 0, "totalAmount":0
                }, {
                    "status": "OverDue",
                    statusId: 3,
                    "totalClients": 0, "totalAmount":0
                }];

                element.stats.forEach(element => {

                    if (element.statusId == 1) {
                        _notes[0].totalClients = element.totalClients
                        _notes[0].totalAmount = element.totalAmount

                    }
                    if (element.statusId == 2) {
                        _notes[1].totalClients = element.total
                        _notes[1].totalAmount = element.totalAmount

                    }
                    if (element.statusId == 3) {

                        _notes[2].totalClients = element.total
                        _notes[2].totalAmount = element.totalAmount

                    }

                });

                this.formateddata.push({
                    name: stateObject.name,
                    data: element.stats
                })

                }
               
            })

            var chart = am4core.create("clientstatefilingsChart", am4maps.MapChart);
            chart.geodata = am4geodata_usaLow;
            chart.projection = new am4maps.projections.AlbersUsa();
            chart.seriesContainer.draggable = false;
            chart.seriesContainer.resizable = false;
            chart.maxZoomLevel = 1;

            // Create map polygon series
            var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

            //Set min/max fill color for each area
            // polygonSeries.fill="fill";
            polygonSeries.fill = am4core.color("#99C78F");

            polygonSeries.mapPolygons.template.events.on("over",  (ev)=>{
                if (ev.target.dataItem.dataContext.name) {
                    self.selectedstate = {
                        name: null,
                        data: null,
                        x:0,
                        y:0
                    }
                    let  selectedstate = _.find(self.formateddata, {
                        "name": ev.target.dataItem.dataContext.name
                    });
                    if(selectedstate){
                        self.selectedstate  = selectedstate;
                        self.selectedstate.x = ev.target.tooltipX-100;
                        self.selectedstate.y = ev.target.tooltipY-120;

                    }
                    
                    //console.log(ev.target.dataItem.dataContext.modalUrl);
                } else {
                    self.selectedstate = {
                        name: null,
                        data: null,  x:0,
                        y:0
                    }
                }
            });

            polygonSeries.mapPolygons.template.events.on("out", function () {
                self.selectedstate = {
                    name: null,
                    data: null,  x:0,
                        y:0
                }
            });

            // Make map load polygon data (state shapes and names) from GeoJSON
            polygonSeries.useGeodata = true;
            polygonSeries.cursorOverStyle=am4core.MouseCursorStyle.pointer
            polygonSeries.cursorOptions.defaultStyle =am4core.MouseCursorStyle.pointer

            var polygonTemplate = polygonSeries.mapPolygons.template;
            //polygonTemplate.tooltipText = "{name}";
            polygonTemplate.stroke = am4core.color("#fff");
            polygonTemplate.fill = am4core.color("#e9ecf0");


            polygonTemplate.cursorOverStyle=am4core.MouseCursorStyle.pointer
  polygonTemplate.cursorOptions.defaultStyle =am4core.MouseCursorStyle.pointer
            // Configure label series
            var labelSeries = chart.series.push(new am4maps.MapImageSeries());
            var labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);

            labelTemplate.horizontalCenter = "middle";
            labelTemplate.verticalCenter = "middle";
            labelTemplate.fontSize = 10;
            labelTemplate.fontWeight = "bold";
            labelTemplate.fill = am4core.color("#fff");
            labelTemplate.interactionsEnabled = false;
            labelTemplate.nonScaling = true;
labelTemplate.cursorOverStyle="pointer";
            var hs2 = labelTemplate.states.create("hover");
            hs2.properties.fill = am4core.color("#fff");

                   polygonTemplate.adapter.add("fill", function(fill, target) {
                

                  if (target.dataItem && target.dataItem.dataContext && target.dataItem.dataContext.name) {


                    var itemd = _.find(self.formateddata, {
                        "name": target.dataItem.dataContext.name
                    });

                    if(itemd && itemd.data.length > 0 && itemd.data[0].statusId == 1){
                            return am4core.color("#a2ed93");

                    }

                       if(itemd && itemd.data.length > 0 && itemd.data[0].statusId == 2){
                            return am4core.color("#84adff");

                    }

                        if(itemd && itemd.data.length > 0 && itemd.data[0].statusId == 3){
                            return am4core.color("#ffa8a8");

                    }

                    //console.log(ev.target.dataItem.dataContext.modalUrl);
                }
  
  return fill;
});

            polygonSeries.events.on("inited", function () {
                for (var i = 0; i < ids.length; i++) {
                    var polygon = polygonSeries.getPolygonById(ids[i]);
                    if (polygon) {
                        var label = labelSeries.mapImages.create();
                        var state = polygon.dataItem.dataContext.id.split("-").pop();
                        label.latitude = polygon.visualLatitude;
                        label.longitude = polygon.visualLongitude;
                        label.children.getIndex(0).text = state;
                        label.fill = am4core.color("#5e5f61");

                                    var hs3 = label.states.create("hover");
            hs3.properties.fill = am4core.color("#fff");
                    }
                }
            });

        }
    },
    components: {},

    methods: {
      
    },
    data: () => ({
        formateddata: [],
        selectedstate: {
            name: null,
            data: null,
            y:0,
            x:0
        },
        states_hash: {
            'Alabama': 'AL',
            'Alaska': 'AK',
            'American Samoa': 'AS',
            'Arizona': 'AZ',
            'Arkansas': 'AR',
            'California': 'CA',
            'Colorado': 'CO',
            'Connecticut': 'CT',
            'Delaware': 'DE',
            'District Of Columbia': 'DC',
            'Federated States Of Micronesia': 'FM',
            'Florida': 'FL',
            'Georgia': 'GA',
            'Guam': 'GU',
            'Hawaii': 'HI',
            'Idaho': 'ID',
            'Illinois': 'IL',
            'Indiana': 'IN',
            'Iowa': 'IA',
            'Kansas': 'KS',
            'Kentucky': 'KY',
            'Louisiana': 'LA',
            'Maine': 'ME',
            'Marshall Islands': 'MH',
            'Maryland': 'MD',
            'Massachusetts': 'MA',
            'Michigan': 'MI',
            'Minnesota': 'MN',
            'Mississippi': 'MS',
            'Missouri': 'MO',
            'Montana': 'MT',
            'Nebraska': 'NE',
            'Nevada': 'NV',
            'New Hampshire': 'NH',
            'New Jersey': 'NJ',
            'New Mexico': 'NM',
            'New York': 'NY',
            'North Carolina': 'NC',
            'North Dakota': 'ND',
            'Northern Mariana Islands': 'MP',
            'Ohio': 'OH',
            'Oklahoma': 'OK',
            'Oregon': 'OR',
            'Palau': 'PW',
            'Pennsylvania': 'PA',
            'Puerto Rico': 'PR',
            'Rhode Island': 'RI',
            'South Carolina': 'SC',
            'South Dakota': 'SD',
            'Tennessee': 'TN',
            'Texas': 'TX',
            'Utah': 'UT',
            'Vermont': 'VT',
            'Virgin Islands': 'VI',
            'Virginia': 'VA',
            'Washington': 'WA',
            'West Virginia': 'WV',
            'Wisconsin': 'WI',
            'Wyoming': 'WY'
        }
    }),
    mounted() {
    },
    computed: {

    },
    beforeCreate() {
        this.userdata = this.$store.getters['auth/getuser'];

    }

}
</script>
