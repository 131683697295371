<template>
<div>
    <div id="clientrevenuechart" style="height:175px;"> </div>

    <div id="ClientSaleschart" style="height:175px;"> </div>

</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);

export default {
    name: 'ClientSales',
    props: {
        chartData: {
            type: [Object, Array],
            default: null,

        },
    },
    watch: {
        chartData(val) {
            var chart = am4core.create("ClientSaleschart", am4charts.XYChart);
            var chart2 = am4core.create("clientrevenuechart", am4charts.XYChart);

            var _data = [];
            if(val["grossSales"]){
                            val["grossSales"].forEach((element,indexd) => {
                _data.push({
                    year: element.name + "-" + element.year,
                    gs: element.totalAmount.toFixed(2),
                    ts: val["taxedSales"][indexd].totalAmount.toFixed(2),
                    st: val["salesTax"][indexd].totalAmount.toFixed(2),

                })
            })

            }



            var n_data = _data;

             var valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
            valueAxis2.fillOpacity = 1;
            valueAxis2.min = 0;
            valueAxis2.renderer.labels.template.fill = am4core.color("#7484a1cc");
            chart2.numberFormatter.numberFormat = "#a";

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.fillOpacity = 1;
            valueAxis.min = 0;
            valueAxis.renderer.labels.template.fill = am4core.color("#7484a1cc");
            chart.numberFormatter.numberFormat = "#a";

            /* Create axes */
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "year";
            categoryAxis.renderer.minGridDistance = 30;


            var categoryAxis2 = chart2.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis2.dataFields.category = "year";
            categoryAxis2.renderer.minGridDistance = 30;

            /* Create value axis */
            chart.yAxes.push(new am4charts.ValueAxis());

              chart2.yAxes.push(new am4charts.ValueAxis());




            var lineSeries2 = chart2.series.push(new am4charts.LineSeries());
            lineSeries2.name = "Taxed Sales";
            lineSeries2.dataFields.valueY = "ts";
            lineSeries2.dataFields.categoryX = "year";

            lineSeries2.stroke = am4core.color("#3085E8");
            lineSeries2.strokeWidth = 3;
            lineSeries2.propertyFields.strokeDasharray = "lineDash";
            lineSeries2.tooltip.label.textAlign = "middle";
            lineSeries2.fillOpacity = 1;



            lineSeries2.fill = am4core.color("#3085E8");
            lineSeries2.tooltip.pointerOrientation = "down"

            lineSeries2.fontSize = 12;
            lineSeries2.tensionX = 0.77;



             var lineSeries3 = chart.series.push(new am4charts.LineSeries());
            lineSeries3.name = "Sales Tax";
            lineSeries3.dataFields.valueY = "st";
            lineSeries3.dataFields.categoryX = "year";

            lineSeries3.stroke = am4core.color("#FF8022");
            lineSeries3.strokeWidth = 3;
            lineSeries3.propertyFields.strokeDasharray = "lineDash";
            lineSeries3.tooltip.label.textAlign = "middle";
            lineSeries3.fillOpacity = 1;

            var fillModifier3 = new am4core.LinearGradientModifier();
            fillModifier3.opacities = [1, 0];
            fillModifier3.offsets = [0, 1];
            fillModifier3.gradient.rotation = 90;
            lineSeries3.segments.template.fillModifier = fillModifier3;

            lineSeries3.fill = am4core.color("#FF8022");
            lineSeries3.tooltip.pointerOrientation = "down"

            lineSeries3.fontSize = 12;
            lineSeries3.tensionX = 0.77;


            categoryAxis.renderer.labels.template.fontSize = 12;

            categoryAxis.renderer.labels.template.fill = am4core.color("#7484a1cc");
            categoryAxis.renderer.minGridDistance = 30;

       
            var lineSeries = chart2.series.push(new am4charts.LineSeries());
            lineSeries.name = "Gross Sales";
            lineSeries.dataFields.valueY = "gs";
            lineSeries.dataFields.categoryX = "year";

            lineSeries.stroke = am4core.color("#75D364");
            lineSeries.strokeWidth = 3;
            lineSeries.propertyFields.strokeDasharray = "lineDash";
            lineSeries.tooltip.label.textAlign = "middle";
            lineSeries.fillOpacity = 1;

            var fillModifier = new am4core.LinearGradientModifier();
            fillModifier.opacities = [1, 0];
            fillModifier.offsets = [0, 1];
            fillModifier.gradient.rotation = 90;
            lineSeries.segments.template.fillModifier = fillModifier;

            lineSeries.fill = am4core.color("#75D364");
            lineSeries.tooltip.pointerOrientation = "down"

            lineSeries.fontSize = 12;
            lineSeries.tensionX = 0.77;

            var bullet = lineSeries.bullets.push(new am4charts.Bullet());
            bullet.fill = am4core.color("#75D364"); // tooltips grab fill from parent by default
            bullet.tooltipText = "[#fff font-size: 15px]Gross Sales:\n[/][#fff font-size: 15px]${gs.formatNumber('#,###.00')}[/] [#fff]\n[#fff font-size: 15px][#fff][/][#fff font-size: 15px]Taxed Sales:\n[/][#fff font-size: 15px]${ts.formatNumber('#,###.00')}[/] [#fff]\n[#fff font-size: 15px][#fff][/]"
            var circle = bullet.createChild(am4core.Circle);
            circle.radius = 4;
            circle.fill = am4core.color("#fff");
            circle.strokeWidth = 3;


                 var bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
            bullet2.fill = am4core.color("#3085E8"); // tooltips grab fill from parent by default
            bullet2.tooltipText = "[#fff font-size: 15px]Gross Sales:\n[/][#fff font-size: 15px]${gs.formatNumber('#,###.00')}[/] [#fff]\n[#fff font-size: 15px][#fff][/][#fff font-size: 15px]{name}:\n[/][#fff font-size: 15px]${ts.formatNumber('#,###.00')}[/] [#fff]\n[#fff font-size: 15px][#fff][/]"
            var circle2 = bullet2.createChild(am4core.Circle);
            circle2.radius = 4;
            circle2.fill = am4core.color("#fff");
            circle2.strokeWidth = 3;


             var bullet3 = lineSeries3.bullets.push(new am4charts.Bullet());
            bullet3.fill = am4core.color("#FF8022"); // tooltips grab fill from parent by default
            bullet3.tooltipText = "[#fff font-size: 15px]{name}:\n[/][#fff font-size: 15px]${st.formatNumber('#,###.00')}[/] [#fff]\n[#fff font-size: 15px][#fff][/]"
            var circle3 = bullet3.createChild(am4core.Circle);
            circle3.radius = 4;
            circle3.fill = am4core.color("#fff");
            circle3.strokeWidth = 3;

            chart.data = n_data;
            chart2.data = n_data;

        }
    },
    components: {},

    methods: {

    },
    data: () => ({}),
    mounted() {

    },
    computed: {

    },
    beforeCreate() {
        this.userdata = this.$store.getters['auth/getuser'];
        // this.userdata = localStorage.getItem('user');

    }

}
</script>
