<template>
<div>
        <div id="revenuechart"> </div>

    <div id="transactionschart"> </div>

</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);

export default {
    name: 'FilingsSummary',
    props: {
        chartData: {
            type: [Object, Array],
            default: null,

        },
    },
    watch: {
        chartData(val) {
            var chart = am4core.create("transactionschart", am4charts.XYChart);
             var chart2 = am4core.create("revenuechart", am4charts.XYChart);

            var _data = [];
            val.forEach(element => {
                _data.push({
                    year: element.name + "-" + element.year,
                    filings: element.count,
                    total: element.totalAmount,
                })
            })

            var n_data = _data;

            /* Create axes */
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "year";
            categoryAxis.renderer.minGridDistance = 30;
            //chart.numberFormatter.numberFormat = "#a";

            /* Create value axis */
            chart.yAxes.push(new am4charts.ValueAxis());

            var lineSeries = chart.series.push(new am4charts.LineSeries());
            lineSeries.name = "Filings";
            lineSeries.dataFields.valueY = "filings";
            lineSeries.dataFields.categoryX = "year";

            lineSeries.stroke = am4core.color("#ff8022");
            lineSeries.strokeWidth = 3;
            lineSeries.propertyFields.strokeDasharray = "lineDash";
            lineSeries.tooltip.label.textAlign = "middle";
            lineSeries.fillOpacity = 1;

            var fillModifier = new am4core.LinearGradientModifier();
            fillModifier.opacities = [1, 0];
            fillModifier.offsets = [0, 1];
            fillModifier.gradient.rotation = 90;
            lineSeries.segments.template.fillModifier = fillModifier;

            lineSeries.fill = am4core.color("#ff8022");
            lineSeries.tooltip.pointerOrientation = "down"

            categoryAxis.renderer.labels.template.fontSize = 12;
            lineSeries.fontSize = 12;
            lineSeries.tensionX = 0.77;

            categoryAxis.renderer.labels.template.fill = am4core.color("#7484a1cc");
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 5;
            var bullet = lineSeries.bullets.push(new am4charts.Bullet());
            bullet.fill = am4core.color("#ff8022"); // tooltips grab fill from parent by default
            bullet.tooltipText = "[#fff font-size: 15px]{name}:\n[/][#fff font-size: 15px]{valueY}[/] [#fff][/]"
            var circle = bullet.createChild(am4core.Circle);
            circle.radius = 4;
            circle.fill = am4core.color("#fff");
            circle.strokeWidth = 3;

            chart.data = n_data;



             /* Create axes */
            var categoryAxis2 = chart2.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis2.dataFields.category = "year";
            categoryAxis2.renderer.minGridDistance = 30;
            chart2.numberFormatter.numberFormat = "#a";

            /* Create value axis */
            chart2.yAxes.push(new am4charts.ValueAxis());

            var lineSeries2 = chart2.series.push(new am4charts.LineSeries());
            lineSeries2.name = "Revenue";
            lineSeries2.dataFields.valueY = "total";
            lineSeries2.dataFields.categoryX = "year";

            lineSeries2.stroke = am4core.color("#2b83e9");
            lineSeries2.strokeWidth = 3;
            lineSeries2.propertyFields.strokeDasharray = "lineDash";
            lineSeries2.tooltip.label.textAlign = "top";
            lineSeries2.tooltip.pointerOrientation = "down"
            lineSeries2.fillOpacity = 1;

            var fillModifier2 = new am4core.LinearGradientModifier();
            fillModifier2.opacities = [1, 0];
            fillModifier2.offsets = [0, 1];
            fillModifier2.gradient.rotation = 90;
            lineSeries2.segments.template.fillModifier = fillModifier2;

            lineSeries2.fill = am4core.color("#2b83e9");

            categoryAxis2.renderer.labels.template.fontSize = 12;
            lineSeries2.fontSize = 12;
            lineSeries2.tensionX = 0.77;

            categoryAxis2.renderer.labels.template.fill = am4core.color("#7484a1cc");
            categoryAxis2.renderer.grid.template.location = 0;
            categoryAxis2.renderer.minGridDistance = 5;
            var bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
            bullet2.fill = am4core.color("#2b83e9"); // tooltips grab fill from parent by default
            bullet2.tooltipText = "[#fff font-size: 15px]Revenue ${total.formatNumber('#,###.00')}[#fff][/]"
            var circle2 = bullet2.createChild(am4core.Circle);
            circle2.radius = 4;
            circle2.fill = am4core.color("#fff");
            circle2.strokeWidth = 3;

            chart2.data = n_data;

        }
    },
    components: {},

    methods: {

    },
    data: () => ({}),
    mounted() {

    },
    computed: {

    },
    beforeCreate() {
        this.userdata = this.$store.getters['auth/getuser'];
        // this.userdata = localStorage.getItem('user');

    }

}
</script>
