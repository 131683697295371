<template>
<div>

    <div class="nodata"  v-if="nodata">No Data Available</div>
    <div >

        <div id="chartdiv"> </div>
    </div>
</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);

export default {
    name: 'FilingsSummary',
    props: {
        chartData: {
            type: [Object, Array],
            default: null,

        },
    },
    watch: {
        chartData(val) {
            var _data = [];

            val.forEach(element => {

                _data.push({
                    year: element.name + "-" + element.year,
                    filings: element.count,
                    total: element.totalAmount,
                })

            })
            if (val.length > 0) {

                this.nodata = false;

            } else {

                this.nodata = true;

            }

            var chart = am4core.create("chartdiv", am4charts.XYChart);

            /* Create axes */
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "year";
            categoryAxis.renderer.minGridDistance = 30;

            /* Create value axis */
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.fillOpacity = 0.6;
            valueAxis.max = 60000;
            valueAxis.min = 0;
            valueAxis.renderer.labels.template.fill = am4core.color("#7484a1cc");
            valueAxis.numberFormatter.numberFormat = "#a";

            var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis2.renderer.opposite = true;
            valueAxis2.renderer.grid.template.disabled = true;
            categoryAxis.renderer.labels.template.fontSize = 12;
            valueAxis.renderer.labels.template.fontSize = 12;
            categoryAxis.renderer.labels.template.fill = am4core.color("#7484a1cc");
            /* Create series */
            var columnSeries = chart.series.push(new am4charts.ColumnSeries());
            columnSeries.name = "Total Tax";
            columnSeries.dataFields.valueY = "total";
            columnSeries.dataFields.categoryX = "year";

            var gradient = new am4core.LinearGradient();
            gradient.addColor(am4core.color("#A2ED93"));
            gradient.addColor(am4core.color("#75d364"));
            gradient.rotation = 90;

            columnSeries.columns.template.column.cornerRadiusTopLeft = 4;
            columnSeries.columns.template.column.cornerRadiusTopRight = 4;

            //columnSeries.numberFormatter.numberFormat = '#,###.00';

            columnSeries.fill = gradient;
            columnSeries.stroke = am4core.color("#A2ED93");
            columnSeries.columns.template.tooltipText = "[#000 font-size: 15px]{name}:\n[/][#000 font-size: 20px]${valueY.formatNumber('#,###.00')}[/] [#000]{additional}[/]"
            columnSeries.tooltip.label.textAlign = "middle";
            columnSeries.tooltip.background.fill = am4core.color("#fff");

            var lineSeries = chart.series.push(new am4charts.LineSeries());
            lineSeries.name = "Filings";
            lineSeries.dataFields.valueY = "filings";
            lineSeries.dataFields.categoryX = "year";
            lineSeries.yAxis = valueAxis2;

            lineSeries.stroke = am4core.color("#000");
            lineSeries.strokeWidth = 3;
            lineSeries.propertyFields.strokeDasharray = "lineDash";
            lineSeries.tooltip.label.textAlign = "middle";
            lineSeries.tensionX = 0.77;

            var bullet = lineSeries.bullets.push(new am4charts.Bullet());
            bullet.fill = am4core.color("#000"); // tooltips grab fill from parent by default
            bullet.tooltipText = "[#fff font-size: 15px]{name}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
            var circle = bullet.createChild(am4core.Circle);
            circle.radius = 4;
            circle.fill = am4core.color("#fff");
            circle.strokeWidth = 3;

            chart.data = _data;

        }
    },
    components: {},

    methods: {

    },
    data: () => ({
        nodata: false
    }),
    mounted() {

    },
    computed: {

    },
    beforeCreate() {
        this.userdata = this.$store.getters['auth/getuser'];
        // this.userdata = localStorage.getItem('user');

    }

}
</script>
