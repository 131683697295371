<template>
<div class="body_section">
    <profileHeader :showFilter="true" @changedGlobalclient="changedGlobalclient" />
    <div class="body_content">

        <div class="main_content">
            <div class="dashboard_cnt">

                <div class="dashboard_left">
                    <div class="d_header" :class="{'d-client_header':userdata.roleId ==5 || ( userdata.roleId !=5 && selectedGlobalClient !=null && checkProperty(selectedClientData, 'logo') !='' && checkProperty(selectedGlobalClient ,'name') !='ALL')}">

                        <template v-if="userdata.roleId !=5 && selectedGlobalClient !=null && checkProperty(selectedClientData, 'logo') !='' && checkProperty(selectedGlobalClient ,'name') !='ALL' ">
                            <div class="d_header_title ">
                                <figure v-if="selectedClientData!=null"><img :src="(checkProperty(selectedClientData, 'logo') !='' && checkProperty(selectedClientData, 'logo') !='--')?checkProperty(selectedClientData, 'logo'):''" @error="setAltImg($event)"></figure>
                                <label>{{checkProperty(selectedGlobalClient ,'name')}}</label>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="userdata.roleId ==5">
                                <div class="d_header_title ">
                                    <figure><img :src="(checkProperty(userdata, 'clientDetails', 'logo') !='' && checkProperty(userdata, 'clientDetails', 'logo') !='--')?checkProperty(userdata, 'clientDetails', 'logo'):''" @error="setAltImg($event)"></figure>
                                    <label>
                                        <template v-if="checkProperty(userdata ,'clientDetails' ,'busName') !='--' && checkProperty(userdata ,'clientDetails' ,'busName') !=''">
                                            {{checkProperty(userdata ,'clientDetails' ,'busName')}}
                                        </template>
                                    </label>
                                </div>
                            </template>
                            <template v-else>
                                <div class="d_header_title align-items-start">
                                    <h6>Welcome back!</h6>
                                    <label>{{checkProperty(userdata ,'name')}}</label>
                                </div>
                            </template>
                        </template>

                        <div class="slider_section dd">
                            <h6 v-if="deadLines.length>0 ">Due Filings
                                <!-- <a>View All</a> -->
                                <router-link to="/fillings" v-if="deadLinesTotalCount>3">
                                    View All
                                </router-link>
                            </h6>
                            <div class="relative">
                                <swiper ref="mySwiper" :options="swiperOptions">
                                    <swiper-slide v-for="( item , index ) in deadLines" :key="index">
                                        <template v-if="deadLines.length>0 && userdata.roleId !=5 && selectedGlobalClient ==null">
                                            <div v-bind:class="{ 'overdueslide': checkoverdue(item.dueDate)}" class="slide-list cr" @click="goTopage(item )">
                                                <label>{{checkProperty(item ,'clientDetails','busName')}}.</label>
                                                <p class="ddate">Due <span>{{item['amount'].toFixed(2) | currency}}</span> by --

                                                    <template v-if="checkProperty(item ,'dueDate') !='--'">
                                                        <span class="ddate"> {{checkProperty(item ,'dueDate') | formatDate }}</span>
                                                    </template>
                                                </p>
                                                <a><img src="@/assets/images/pointer.svg">
                                                    {{checkProperty(item ,'stateDetails' ,'name')  }}</a>
                                            </div>
                                        </template>
                                        <template v-else-if="deadLines.length>0 ">
                                            <div v-bind:class="{ 'overdueslide': checkoverdue(item.dueDate)}" class="slide-list cr" @click="goTopage(item )">
                                                <label>{{checkProperty(item ,'stateDetails' ,'name')  }}</label>
                                                <p class="ddate">Due <span>{{item['amount'].toFixed(2) | currency}}</span> by
                                                    <template v-if="checkProperty(item ,'dueDate') !='--'">
                                                        {{checkProperty(item ,'dueDate') | formatDate }}
                                                    </template></p>
                                            </div>
                                        </template>
                                    </swiper-slide>

                                </swiper>
                                <div class="slider_controls">
                                    <div class="slider_navigation">
                                        <div id="prev1" class="swiper-button-prev">
                                            <img src="@/assets/images/next-arrow.svg">
                                        </div>
                                        <div id="next1" class="swiper-button-next">
                                            <img src="@/assets/images/next-arrow.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider_section statewise" style="display:none">
                            <h6>Due Filings
                                <!-- <a>View All</a> -->
                                <router-link to="/fillings" v-if="deadLinesTotalCount>3">
                                    View All
                                </router-link>
                            </h6>
                            <div class="relative">
                                <swiper ref="mySwiper" :options="swiperOptions">
                                    <!-- <swiper-slide  v-for="( item , index ) in deadLines"  :key="index">
                                    <div class="slide-list">
                                        <label>{{checkProperty(item ,'clientDetails','busName')}}.</label>
                                        <p>Due <span>${{item['amount'].toFixed(2)}}</span> by

                                        <template v-if="checkProperty(item ,'dueDate') !='--'">
                                                {{checkProperty(item ,'dueDate') | formatDate }}
                                        </template>
                                        </p>
                                        <a><img src="@/assets/images/pointer.svg">
                                        {{checkProperty(item ,'stateDetails' ,'name')  }}</a>
                                    </div>
                                </swiper-slide> -->
                                    <swiper-slide v-for="( item , index ) in deadLines" :key="index">

                                    </swiper-slide>
                                    <!--
                                <swiper-slide>
                                    <div class="slide-list">
                                        <label>Utah</label>
                                        <p>Due <span>$251</span> by 1 Dec 2020</p>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="slide-list">
                                        <label>Arizona</label>
                                        <p>Due <span>$251</span> by 1 Dec 2020</p>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="slide-list">
                                        <label>Utah</label>
                                        <p>Due <span>$251</span> by 1 Dec 2020</p>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="slide-list">
                                        <label>Arizona</label>
                                        <p>Due <span>$251</span> by 1 Dec 2020</p>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="slide-list">
                                        <label>Utah</label>
                                        <p>Due <span>$251</span> by 1 Dec 2020</p>
                                    </div>
                                </swiper-slide>
                                -->
                                </swiper>
                                <div class="slider_controls">
                                    <div class="slider_navigation">
                                        <div id="prev2" class="swiper-button-prev">
                                            <img src="@/assets/images/next-arrow.svg">
                                        </div>
                                        <div id="next2" class="swiper-button-next">
                                            <img src="@/assets/images/next-arrow.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="userdata.roleId !=5 && selectedGlobalClient ==null">
                        <div class="row">
                            <div class="d-col marb20">
                                <div class="d_block">
                                    <div class="d_title">
                                        <label><span><img src="@/assets/images/cart.svg"></span>Filings</label>

                                        <date-range-picker v-if="disabled" placeholder="YYYY-MM-DD" :maxDate="new Date()" :autoApply="''" :opens="'left'" :ranges="false" v-model="filing_revenueRange"></date-range-picker>

                                    </div>

                                    <div class="fillings_graph">
                                        <template v-if="filing_revenue.chartData.length > 0">
                                            <FilingsSummary :chartData="filing_revenue.chartData" />
                                            <ul class="graph_indications">
                                                <li class="amount">Tax Amount/Tax paid</li>
                                                <li class="orders">Filings</li>
                                            </ul>
                                        </template>
                                        <template v-else>
                                            <div class="nodata">No Data Available</div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="d-col marb20" v-if="userdata.roleId ==1">
                                <div class="d_block">
                                    <div class="d_title">
                                        <label><span><img src="@/assets/images/graph.svg"></span>Revenue & Filings</label>

                                    </div>
                                    <div class="fillings_graph">
                                        <template v-if="projected_revenue.chartData.length > 0">
                                            <Filings :chartData="projected_revenue.chartData" />
                                            <ul class="graph_indications">
                                                <li class="revenue">Revenue</li>
                                                <li class="transactions">Filings</li>
                                            </ul>
                                        </template>
                                        <template v-else>
                                            <div class="nodata">No Data Available</div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="tax_widget marb20">
                                <div class="d_block">
                                    <div class="d_title">
                                        <label><span><img src="@/assets/images/tax.svg"></span>Tax</label>
                                        <month-picker-input :input-pre-filled='true' :max-date="new Date()" placement="right" :no-default="false" :show-year="true" :default-year="fIlingChartData.year" :default-month="fIlingChartData.month" @change="changeFIlingChartData"></month-picker-input>

                                    </div>
                                    <div class="fillings_graph" v-if="fIlingChartData.chartData">

                                        <FilingsCount :fIlingChartData="fIlingChartData.chartData" />

                                    </div>
                                </div>
                            </div>
                            <div class="filling marb20" v-if="this.userdata.roleId != 5">
                                <div class="d_block">
                                    <div class="d_title">
                                        <label><span><img src="@/assets/images/state_fillings.svg"></span>State wise filings</label>
                                        <month-picker-input :input-pre-filled='true' :max-date="new Date()" placement="right" :no-default="false" :show-year="true" :default-year="stateChartData.year" :default-month="stateChartData.month" @change="changeStateChartData"></month-picker-input>

                                    </div>
                                    <div class="fillings_graph" >
                                        <template v-if="usstateList.length > 0">
                                            <StateFilings :usstateList="usstateList" :chartData="stateChartData.chartData" />
                                        </template>
                                        <template v-if="usstateList.length <= 0">
                                            <div class="nodata">No Data Available</div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row marb20" v-if="this.userdata.roleId != 5">
                            <div class="col">
                                <div class="d_block">
                                    <div class="d_title">
                                        <label><span><img src="@/assets/images/state_fillings.svg"></span>State wise filings</label>
                                        <div class="state_filing_actions">
                                            <ul class="status">
                                                <li class="filed">Filed</li>
                                                <li class="nextdue">Due</li>
                                                <li class="overdue">Overdue</li>
                                            </ul>
                                            <month-picker-input :input-pre-filled='true' :max-date="new Date()" placement="right" :no-default="false" :show-year="true" :default-year="stateChartData.year" :default-month="stateChartData.month" @change="changeStateChartData"></month-picker-input>
                                        </div>

                                    </div>
                                    <div class="fillings_graph" v-if="usstateList.length > 0">
                                        <ClientStateFilings :usstateList="usstateList" :chartData="stateChartData.chartData" />

                                    </div>
                                    <div v-else class="nodata no-data">No Data Available</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col" v-if="this.userdata.roleId != 5">
                                <div class="d_block">
                                    <div class="d_title">
                                        <label><span><img src="@/assets/images/graph.svg"></span>Sales</label>

                                        <date-range-picker v-if="disabled" placeholder="YYYY-MM-DD" :maxDate="new Date()" :autoApply="''" :opens="'left'" :ranges="false" v-model="clientsales_revenueRange"></date-range-picker>

                                    </div>
                                    <div class="fillings_graph sales_graph">
                                        <div class="sales_summary" v-if="clientsalesdata.chartData && clientsales && clientsales.gs!=null">
                                            <h6>Summary</h6>
                                            <ul>
                                                <li class="gross">
                                                    <span>Gross Sales</span>
                                                    <label>{{clientsales.gs | currency}}</label>
                                                </li>
                                                <li class="taxed">
                                                    <span>Taxed Sales</span>
                                                    <label>{{clientsales.ts | currency}}</label>
                                                </li>
                                                <li class="sales">
                                                    <span>Sales Tax</span>
                                                    <label>{{clientsales.st | currency}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="nodata" v-if="clientsalesdata.chartData && clientsales.gs==null">
                                            <div class="nodata">No Data Available</div>
                                        </div>
                                        <div class="sales_graph_cnt">
                                            <ClientSales :chartData="clientsalesdata.chartData" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                </div>

                <div class="dashboard_right">

                    <div class="d_todo_list" v-if="todoList.length>0 && userdata.roleId !=5">
                        <div class="d_title">
                            <label><span><img src="@/assets/images/note_icon.svg"></span>To Do List</label>
                            <router-link to="/todos" >View All</router-link>
                        </div>
                        <ul>
                            <li v-for="(todo , index ) in todoList" :Key="index">
                                <p @click="goTopage(todo)">
                                    <template v-if="checkProperty(todo  , 'clientDetails' ,'busName')!='--'">
                                        Filing for <a>{{todo['clientDetails']["busName"]}} </a>
                                    </template>

                                    {{checkProperty( todo,"data" ,'description' )}}
                                    <b-icon icon="arrow-right-square" aria-hidden="true"></b-icon>
                                </p>
                            </li>

                            <!--  <li>
                            <p>Filing for <a>ABC company</a> sales tax for Jan 20</p>
                        </li>
                        <li class="read">
                            <p>New client profile updated <a>Ideateq</a></p>
                        </li>
                       <li>
                            <p>Review and submit daily pending tax filings</p>
                        </li>
                        <li>
                            <p>New client profile updated <a>Ideateq</a></p>
                        </li>-->
                        </ul>
                    </div>
                    <div class="d_notes_list" v-if="this.userdata.roleId != 5">
                        <div class="d_title">
                            <label><span><img src="@/assets/images/note_icon.svg"></span>Notes</label>
                            <router-link to="/notes" v-if="notesListTotalCount>4">View All</router-link>
                        </div>
                        <ul>
                            <li v-for="(note ,index) in notesList" :key="index">
                                <h6>{{note.title}}</h6>
                                <p>{{note.description}}</p>
                                <span>{{note.createdOn | formatDate}}</span>
                            </li>

                        </ul>
                        <div class="no_data" v-if="notesList.length<=0"> No Data!</div>
                    </div>

                </div>
            </div>
        </div>

        <footerView ref="rout_view"></footerView>
    </div>

</div>
</template>

<script>
import profileHeader from "@/components/ProfileHeader.vue"
import footerView from "@/components/footer.vue"
import FilingsSummary from "@/views/charts/FilingsSummary.vue"
import Filings from "@/views/charts/Filings.vue"
import StateFilings from "@/views/charts/StateFilings.vue"
import ClientStateFilings from "@/views/charts/ClientStateFilings.vue"
import ClientSales from "@/views/charts/ClientSales.vue"

import FilingsCount from "@/views/charts/FilingsCount.vue"

import {
    MonthPickerInput
} from 'vue-month-picker'

import _ from "lodash";
import {
    Swiper,
    SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
//import "@/assets/public/css/swiper.css"

import moment from 'moment'

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    name: 'Dashboard',

    components: {
        MonthPickerInput,
        Swiper,
        SwiperSlide,
        profileHeader,
        footerView,
        Filings,
        FilingsSummary,
        FilingsCount,
        DateRangePicker,
        StateFilings,
        ClientStateFilings,
        ClientSales
    },

    methods: {
        checkoverdue(date) {

            return moment(date).isBefore(moment(new Date()))

        },
        changeclientsalesdata(date) {

            this.clientsalesdata['year'] = parseInt(date.year);
            this.clientsalesdata['month'] = parseInt(date.monthIndex);
            this.getDashboard("clientsalesdata");

        },
        changeStateChartData(date) {
            this.stateChartData['year'] = parseInt(date.year);
            this.stateChartData['month'] = parseInt(date.monthIndex);
            this.getDashboard("stateChartData");

        },

        changeFIlingChartData(date) {
            this.fIlingChartData.chartData = null;
            this.fIlingChartData['year'] = parseInt(date.year);
            this.fIlingChartData['month'] = parseInt(date.monthIndex);
            this.getDashboard("fIlingChartData");

            //fIlingChartData:{"clientId": "","year": 2021,"month": 3,"today": "2021-03-03", "types": [ "FILING"] ,'chartData':[]},
        },
        goTopage(item) {
            if (this.userdata && this.userdata['roleId'] == 5) {
                this.$router.push('/fillings');

            } else {
                if (_.has(item, "data")) {
                    if (item['data']['navigationKey'] == "FILING_DETAILS") {
                        this.$router.push('/upload-returns/' + item['data']['filingId']);

                    }

                } else if (_.has(item, "_id")) {
                    this.$router.push('/upload-returns/' + item['_id']);

                }

            }

        },
        getDashboard(chartType = "fIlingChartData") {

            let postData = {};
            if (chartType == "fIlingChartData") {
                postData = this.fIlingChartData;
                this.fIlingChartData.chartData = [];
            }
            if (chartType == "filing_revenue") {
                postData = this.filing_revenue;
                this.filing_revenue.chartData = [];
            }

            if (chartType == "projected_revenue") {
                postData = this.projected_revenue;
                this.projected_revenue.chartData = [];

            }
            if (chartType == "stateChartData") {
                postData = this.stateChartData;
                this.stateChartData.chartData = [];

            }

            if (chartType == "clientsalesdata") {

                postData = this.clientsalesdata;
                this.clientsalesdata.chartData = [];

            }

            postData['clientId'] = '';
            if (this.selectedGlobalClient != null && _.has(this.selectedGlobalClient, "_id") && (this.selectedGlobalClient['_id'] != '' && this.selectedGlobalClient['_id'] != 'null' && this.selectedGlobalClient['_id'] != null)) {
                postData['clientId'] = this.selectedGlobalClient['_id'];
                this.projected_revenue.clientId = postData['clientId'];
                this.filing_revenue.clientId = postData['clientId'];
                this.fIlingChartData['clientId'] = postData['clientId'];
                this.stateChartData['clientId'] = postData['clientId'];
            }

            this.$store
                .dispatch("getDashboard", postData)
                .then((response) => {
                    this.filingrevenue = response;

                    if (chartType == "fIlingChartData") {
                        this.fIlingChartData.chartData = response['filings'];
                    }
                    if (chartType == "filing_revenue") {
                        this.filing_revenue.chartData = response['filingRevenues'];
                    }
                    if (chartType == "projected_revenue") {

                        this.projected_revenue.chartData = response['projectedRevenues'];
                    }
                    if (chartType == "stateChartData") {
                        this.stateChartData.chartData = response['stateWiseFilings'];

                    }
                    if (chartType == "clientsalesdata") {
                        this.clientsalesdata.chartData = response['sales'];
                        if (this.clientsalesdata.chartData["grossSales"] && this.clientsalesdata.chartData["grossSales"].length > 0) {
                            var gs = 0;
                            this.clientsalesdata.chartData["grossSales"].forEach(function (item) {
                                gs = gs + item.totalAmount

                            })

                            this.clientsales.gs = gs.toFixed(2)
                        }

                        if (this.clientsalesdata.chartData["taxedSales"] && this.clientsalesdata.chartData["taxedSales"].length > 0) {
                            var ts = 0;
                            this.clientsalesdata.chartData["taxedSales"].forEach(function (item) {
                                ts = ts + item.totalAmount

                            })

                            this.clientsales.ts = ts.toFixed(2)
                        }

                        if (this.clientsalesdata.chartData["salesTax"] && this.clientsalesdata.chartData["salesTax"].length > 0) {
                            var st = 0;
                            this.clientsalesdata.chartData["salesTax"].forEach(function (item) {
                                st = st + item.totalAmount

                            })

                            this.clientsales.st = st.toFixed(2)
                        }
                    }

                })
                .catch((err) => {
                    this.log(err);
                });

        },
        getTodoList() {

            let postData = {
                "filters": {
                    "title": "",
                    "clientIds": [],
                    "statusIds": [],
                    "stateIds": [],
                    "createdOnRange": []
                },
                sorting: {
                    path: "createdOn",
                    order: -1
                }, //{"path": "name","order": -1	},
                page: 1,
                perpage: 4,
            };

            if (_.has(this.selectedGlobalClient, "_id") && (this.selectedGlobalClient['_id'] != '' && this.selectedGlobalClient['_id'] != 'null' && this.selectedGlobalClient['_id'] != null)) {
                postData['filters']['clientIds'].push(this.selectedGlobalClient['_id']);
            }
            this.todoList = [];
            this.todoListTotalCount = 0;
            this.$store
                .dispatch("getTodoList", postData)
                .then((response) => {
                    this.todoList = response.list;
                    this.todoListTotalCount = response.totalCount;

                })
                .catch((err) => {
                    this.log(err);
                });

        },
        getClientdetails() {
            this.selectedClientData = null;

            if (_.has(this.selectedGlobalClient, "_id") && this.selectedGlobalClient['_id'] != null && this.selectedGlobalClient['_id'] != 'null') {
                this.$store.dispatch("getClientdetails", {
                    "clientId": this.selectedGlobalClient['_id']
                }).then((res) => {
                    this.selectedClientData = res;

                });

            }

        },

        changedGlobalclient(cl) {
            this.selectedGlobalClient = cl;
            this.getClientdetails();
            this.getdeadLines();
            this.getTodoList();

            this.getDashboard('filing_revenue');
            this.getDashboard('projected_revenue');

            this.getDashboard();
            this.getDashboard("stateChartData");
            this.getDashboard("clientsalesdata");

            //this.$refs.rout_view.serchTitle(cl);
        },
        getUsStatesList() {

            let payLoad = {
                "matcher": {
                    "searchString": "",
                    "cntryIds": [231]
                },
                "page": 1,
                "perpage": 10000000000,
                "category": "state"
            };
            this.$store.dispatch("getMaterData", payLoad)
                .then((res) => {
                    this.usstateList = res.list;

                });
        },
        getNoteList() {
            let postData = {
                filters: {},
                sorting: {
                    path: "createdOn",
                    order: -1
                }, //{"path": "name","order": -1	},
                page: 1,
                perpage: 4,
            };
            this.notesList = [];
            this.notesListTotalCount = 0;
            this.$store
                .dispatch("getNotesList", postData)
                .then((response) => {
                    this.notesList = response.list;
                    this.notesListTotalCount = response.totalCount;

                })
                .catch((err) => {
                    this.log(err);
                });
        },
        getdeadLines() {

            let postData = {
                filters: {
                    "title": "",
                    "clientIds": [],
                    "statusIds": [],
                    "stateIds": [],
                    "createdOnRange": []
                },
                sorting: {
                    "path": "dueDate",
                    "order": 1
                },
                page: 1,
                perpage: 10,
            };
            if (_.has(this.selectedGlobalClient, "_id") && (this.selectedGlobalClient['_id'] != '' && this.selectedGlobalClient['_id'] != 'null' && this.selectedGlobalClient['_id'] != null)) {
                postData['filters']['clientIds'].push(this.selectedGlobalClient['_id']);
            }
            postData['filters']['title'] = this.serch_title;
            this.deadLinesTotalCount = 0;
            this.list = [];
            this.deadLines = [];
            this.$store.dispatch("getList", {
                data: postData,
                path: "/deadlines/list"
            }).then((response) => {
                this.deadLines = response.list;
                this.deadLinesTotalCount = response.totalCount;

            }).catch((err) => {
                this.log(err);
            });
        },

    },
    data: () => ({
        disabled: true,
        //"types": [ "FILING", "PROJECTED_REVENUE", "FILING_REVENUE"]
        //filing_revenue Chart
        filing_revenueRange: {
            startDate: moment().subtract(6, 'month').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        },
        clientsales_revenueRange: {
            startDate: moment().subtract(6, 'month').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        },
        filing_revenue: {
            "clientId": "",
            "duration": [],
            "today": moment().format('YYYY-MM-DD'),
            "types": ["FILING_REVENUE"],
            'chartData': []
        },

        //projected_revenue chart
        projected_revenueRange: {
            startDate: moment().subtract(6, 'month').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        },
        projected_revenue: {
            "clientId": "",
            "duration": [],
            "today": moment().format('YYYY-MM-DD'),
            "types": ["PROJECTED_REVENUE"],
            'chartData': []
        },

        fIlingChartData: {
            "clientId": "",
            "year": parseInt(moment().format('YYYY')),
            "month": parseInt(moment().format('M')),
            "today": moment().format('YYYY-MM-DD'),
            "types": ["FILING"],
            'chartData': []
        },
        stateChartData: {
            "clientId": "",
            "year": parseInt(moment().format('YYYY')),
            "month": parseInt(moment().format('M')),
            "today": moment().format('YYYY-MM-DD'),
            "types": ["STATEWISE_FILING"],
            'chartData': []
        },
        clientsalesdata: {
            "clientId": "",
            "duration": [],
            "today": moment().format('YYYY-MM-DD'),
            "types": ["SALES"],
            'chartData': []
        },

        usstateList: [],
        filingrevenue: null,
        selectedClientData: null,
        value: null,
        selectedGlobalClient: null,
        options: ['January', 'February', 'March'],
        deadLines: [],
        deadLinesTotalCount: 0,
        userdata: null,
        notesList: [],
        notesListTotalCount: 0,
        swiperOptions: {
            slidesPerView: "auto",
            spaceBetween: 10,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '#next1',
                prevEl: '#prev1'
                // nextEl: this.$el.querySelector('.swiper-button-next'),
                // prevEl: this.$el.querySelector('.swiper-button-prev')
            },
            // Some Swiper option/callback...
        },

        todoList: [],
        filingsChartData: null,
        clientsales: {},
    }),
    mounted() {
        this.getUsStatesList();
        //filing_revenue
        this.filing_revenueRange = {
                startDate: moment().subtract(6, 'month').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            this.filing_revenue['duration'] = [];
        this.filing_revenue['duration'].push(this.filing_revenueRange['startDate']);
        this.filing_revenue['duration'].push(this.filing_revenueRange['endDate']);
        this.filing_revenue.types = ['FILING_REVENUE'];
        this.getDashboard('filing_revenue');

        //projected_revenue
        this.projected_revenueRange = {
                startDate: moment().subtract(6, 'month').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            this.projected_revenue['duration'] = [];
        this.projected_revenue['duration'].push(this.projected_revenueRange['startDate']);
        this.projected_revenue['duration'].push(this.projected_revenueRange['endDate']);
        this.projected_revenue.types = ['PROJECTED_REVENUE'];
        this.getDashboard('projected_revenue');

        this.userdata = this.$store.getters['auth/getuser'];
        //alert( JSON.stringify(this.userdata));

        if (_.has(this.userdata, "clientDetails") && this.userdata['roleId'] == 5 && this.userdata['clientDetails']['statusId'] < 2) {

            this.$router.push({
                "name": 'questionnaire',
                params: {
                    clientId: this.userdata['clientDetails']['_id']
                }
            });

        }

        this.selectedGlobalClient = this.$store.getters['getGlobalClient'];

        this.getdeadLines();
        this.getNoteList();
        this.getTodoList();

        this.getDashboard();
        this.getDashboard('stateChartData');
        this.getDashboard("clientsalesdata");

    },
    computed: {
        //   swiper() {
        //     return this.$refs.mySwiper.$swiper
        //   }
    },
    beforeCreate() {
        this.userdata = this.$store.getters['auth/getuser'];
        // this.userdata = localStorage.getItem('user');

    },
    watch: {
        filing_revenueRange: function (val) {
            this.filing_revenue['duration'] = [],
                this.filing_revenue.duration.push(moment(val.startDate).format('YYYY-MM-DD'))
            this.filing_revenue.duration.push(moment(val.endDate).format('YYYY-MM-DD'))
            this.filing_revenue['types'] = ['FILING_REVENUE'];
            this.getDashboard("filing_revenue");
        },
        clientsales_revenueRange: function (val) {
            this.clientsales = {};
            this.clientsalesdata['duration'] = [],
                this.clientsalesdata.duration.push(moment(val.startDate).format('YYYY-MM-DD'))
            this.clientsalesdata.duration.push(moment(val.endDate).format('YYYY-MM-DD'))
            this.clientsalesdata['types'] = ['SALES'];
            this.getDashboard("clientsalesdata");

        },
        projected_revenueRange: function (val) {
            this.projected_revenue['duration'] = [],
                this.projected_revenue.duration.push(moment(val.startDate).format('YYYY-MM-DD'))
            this.projected_revenue.duration.push(moment(val.endDate).format('YYYY-MM-DD'))

            this.projected_revenue['types'] = ['PROJECTED_REVENUE'];
            this.getDashboard('projected_revenue');
        }
    }

}
</script>

<style>
.statediv {
    width: 100%;
    height: 380px;
}
</style>
