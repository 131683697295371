<template>
<div>

    <div class="nodata" v-if="nodata">No Data Available</div>
    
    <template >
        <div >
            <div :show="!nodata" id="FilingsCountChart" style="height:350px"> </div>
        </div>
        <ul class="legendlist" v-if="!nodata">
            <li class="filed">
                <p>Filed</p>
            </li>
            <li class="nextdate">
                <p>Due</p>
            </li>
            <li class="overdue">
                <p>Overdue</p>
            </li>
        </ul>
    </template>
</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);
var _chartcustom=null;
export default {
    name: 'FilingsCount',
    components: {},
    props: {
        fIlingChartData: {
            type: [Object, Array],
            default: null,

        }
    },
    watch: {
        fIlingChartData(val) {
 
            _chartcustom = am4core.create("FilingsCountChart", am4charts.PieChart);

            var colorSet = new am4core.ColorSet();
            colorSet.list = ["#A2ED93", "#84ADFF", "#FFA8A8"].map(function (color) {
                return new am4core.color(color);
            });

            // Set inner radius
            _chartcustom.innerRadius = am4core.percent(50);

            // Add and configure Series
            var pieSeries = _chartcustom.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "count";
            pieSeries.dataFields.category = "status";

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;
            pieSeries.colors = colorSet;

            var total = 0;
            var _notes = [{
                "status": "Filed",
                "count": 0
            }, {
                "status": "Due",
                "count": 0
            }, {
                "status": "OverDue",
                "count": 0
            }];

            val.forEach(element => {

                if (element.statusId == 1) {
                    _notes[0].count = element.total

                }
                if (element.statusId == 2) {
                    _notes[1].count = element.total

                }
                if (element.statusId == 3) {

                    _notes[2].count = element.total

                }
                total = total + element.total

            });
            _chartcustom.data = _notes;
            if (total == 0) {
                this.nodata = true;
            } else {
                this.nodata = false;
            }

        }

    },
    methods: {

    },
    data: () => ({
        nodata: false
    }),
    mounted() {
        am4core.ready(function () {

        }); // end am4core.ready()

    },
    computed: {

    },
    beforeCreate() {
        this.userdata = this.$store.getters['auth/getuser'];
        // this.userdata = localStorage.getItem('user');

    }

}
</script>
